import { permissionEnum } from 'src/guards/PermissionEnum';
import { SettingModel } from 'src/models/SettingModel';

export const MenuSettings: SettingModel[] = [
  {
    label: 'Configurações da Clínica',
    key: 'clinica',
    to: '/admin/clinica',
    icon: 'ri-hospital-line',
    requiredPermission: permissionEnum.Admin,
  },
  {
    label: 'Configurações de Profissionais',
    key: 'profissional',
    to: '/admin/profissional',
    icon: 'ri-nurse-line',
    requiredPermission: permissionEnum.Admin,
  },
  {
    label: 'Meu perfil',
    key: 'meu-perfil',
    to: '/admin/profissional',
    icon: 'ri-user-line',
  },
];
