import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  isLoadingProfessionalPA: false,
  isLoadingPatient: false,
  isLoadingHistory: false,
  error: null,
  professionals: [],
  selectedProfessional: null,
  edit: null,
  procedures: [],
  experience: null,
  upload: null,
  online: null,
  pacients: undefined,
  cids: [],
  attendance: null,
  patient: null,
  newPatient: null,
  healthOptions: [],
  planOptions: [],
  activeAttendanceId: null,
  activeAttendance: null,
  history: null,
};

const PacientSlice = createSlice({
  name: 'pacientSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingProfessionalPA(state) {
      state.isLoadingProfessionalPA = true;
    },

    startLoadingPatient(state) {
      state.isLoadingPatient = true;
    },

    startLoadingHistory(state) {
      state.isLoadingHistory = true;
    },

    stopLoadingPatient(state) {
      state.isLoadingPatient = false;
    },

    hasError(state, action) {
      console.error('has error: ', action.payload);
      state.isLoading = false;
      state.error = action.payload;
    },

    hasProfessionalPAError(state, action) {
      state.isLoadingProfessionalPA = false;
      state.error = action.payload;
    },

    getAllSuccess(state, action) {
      state.isLoading = false;
      state.pacients = action.payload;
    },

    updateMedicalRecordSuccess(state, action) {
      state.isLoading = false;
    },

    getNewPatientSuccess(state, action) {
      state.isLoading = false;
      state.newPatient = action.payload;
    },

    getAllCidsSuccess(state, action) {
      state.isLoading = false;
      state.cids = action.payload;
    },

    getAttendanceSuccess(state, action) {
      state.isLoading = false;
      state.attendance = action.payload;
    },

    getPatientSuccess(state, action) {
      state.patient = action.payload;
      state.isLoadingPatient = false;
    },

    getProceduresSuccess(state, action) {
      state.isLoading = false;
      state.procedures = action.payload;
    },

    deleteProcedureSuccess(state, action) {
      state.isLoading = false;
    },

    selectProfessionalSuccess(state, action) {
      state.isLoadingProfessionalPA = false;
      state.selectedProfessional = action.payload;
    },
    editSuccess(state, action) {
      state.isLoading = false;
      state.experience = action.payload;
    },
    experienceSuccess(state, action) {
      state.isLoading = false;
      state.experience = action.payload;
    },
    removeSelectedProfessionalSuccess(state, action) {
      state.isLoading = false;
      state.selectedProfessional = null;
    },
    uploadSuccess(state, action) {
      state.isLoading = false;
      state.upload = action.payload;
    },
    onlineSuccess(state, action) {
      state.isLoading = false;
      state.online = action.payload;
    },

    getOptionsSuccess(state, action) {
      state.healthOptions = action.payload.health;
      state.planOptions = action.payload.plan;
    },

    resetPatient(state) {
      state.patient = null;
    },

    getActiveAttendanceId(state, action) {
      state.activeAttendanceId = action.payload.id;
      state.activeAttendance = action.payload;
    },

    getHistorySuccess(state, action) {
      state.history = action.payload;
      state.isLoadingHistory = false;
    },
  },
});

export default PacientSlice;

export const { selectEvent, resetPatient, startLoadingHistory } =
  PacientSlice.actions;

export const getAllPacients = async (clinicId, professionalId) => {
  const url = professionalId
    ? `/patient/clinic/${clinicId}?professionalId=${professionalId}`
    : `/patient/clinic/${clinicId}`;

  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.get(url);
    dispatch(PacientSlice.actions.getAllSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
    return { error: 'Não foi possível buscar os pacientes!' };
  }
};

export const updatePatientMedicalRecord = (patientId, professionalIds) => {
  return async dispatch => {
    try {
      dispatch(PacientSlice.actions.startLoading());
      const response = await axios.patch(
        `/patient/medical-record/${patientId}`,
        professionalIds
      );
      dispatch(PacientSlice.actions.updateMedicalRecordSuccess(response.data));
      return { status: response.status, message: response.data };
    } catch (error) {
      dispatch(PacientSlice.actions.hasError(error));
      return { status: error.response?.status, message: error.response?.data };
    }
  };
};

export const getAllCids = async () => {
  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.get(`/cid`);
    dispatch(PacientSlice.actions.getAllCidsSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
  }
};

export const selectProfessionalPA = async data => {
  try {
    dispatch(PacientSlice.actions.startLoadingProfessionalPA());
    const response = await axios.get(`/professional/${data}`);
    dispatch(PacientSlice.actions.selectProfessionalSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasProfessionalPAError(error));
  }
};

export const getCidByQuery = async query => {
  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.get(`/cid/${query}`);
    dispatch(PacientSlice.actions.getAllCidsSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
  }
};

export const getPacientById = async data => {
  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.get(`/patient/${data}`);
    dispatch(PacientSlice.actions.getAllSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
  }
};

export function getPatientById(patientId) {
  return async () => {
    try {
      dispatch(PacientSlice.actions.startLoadingPatient());
      const response = await axios.get(`/patient/${patientId}`);
      dispatch(PacientSlice.actions.getPatientSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export const getPacientAttendance = async (patientId, professionalId) => {
  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.get(
      `/attendance/professional/${professionalId}/patient/${patientId}`
    );
    dispatch(PacientSlice.actions.getAttendanceSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
  }
};

export const createPacients = async data => {
  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.post(`/patient`, data);
    dispatch(PacientSlice.actions.getNewPatientSuccess(response.data));
    getAllPacients(data.clinic);
    if (response.data.success === false) {
      return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
  }
};

export function updatePatient(patientId, data) {
  return async () => {
    try {
      dispatch(PacientSlice.actions.startLoadingPatient());
      const response = await axios.put(`/patient/${patientId}`, data);
      getAllPacients(data.clinic);
      dispatch(PacientSlice.actions.stopLoadingPatient());
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export const deletePacient = async data => {
  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.delete(`/patient/${data}`);
    getAllPacients(response.data.clinic);
    dispatch(PacientSlice.actions.getAllSuccess(response.data));
    dispatch(PacientSlice.actions.stopLoadingPatient());
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
  }
};

export const editPacientById = async (data, id) => {
  try {
    dispatch(PacientSlice.actions.startLoading());
    const response = await axios.put(`/patient/${id}`, data);
    dispatch(PacientSlice.actions.getAllSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(PacientSlice.actions.hasError(error));
  }
};

export function getOptions(clinicId) {
  return async () => {
    try {
      const auxHealths = [];
      const auxPlans = [];
      const response = await axios.get(
        `/clinic/health-insurance/clinic/${clinicId}`
      );
      response.data.forEach(h => {
        auxHealths.push({ value: h.name, label: h.name });
        h.plans.forEach(p => {
          auxPlans.push({ health: h.name, value: p.name, label: p.name });
        });
      });
      dispatch(
        PacientSlice.actions.getOptionsSuccess({
          health: auxHealths,
          plan: auxPlans,
        })
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getHistoryPatient(professionalId, patientId) {
  return async () => {
    dispatch(PacientSlice.actions.startLoadingHistory());
    try {
      const response = await axios.get(
        `/attendance/professional/${professionalId}/patient/${patientId}`
      );
      dispatch(PacientSlice.actions.getHistorySuccess(response.data.reverse()));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getAttendanceById(attendanceId) {
  return async () => {
    try {
      const response = await axios.get(`/attendance/${attendanceId}`);
      dispatch(PacientSlice.actions.getActiveAttendanceId(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function startAttendance(data) {
  return async () => {
    try {
      const response = await axios.post(`/attendance`, data);
      dispatch(PacientSlice.actions.getActiveAttendanceId(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateAttendance(attendanceId, data) {
  return async () => {
    try {
      const response = await axios.put(`/attendance/${attendanceId}`, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateAttendanceExams(attendanceId, data) {
  return async () => {
    dispatch(PacientSlice.actions.startLoadingHistory());
    try {
      const response = await axios.post(
        `/attendance/${attendanceId}/exams`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateAttendanceAnamnese(attendanceId, data) {
  return async () => {
    dispatch(PacientSlice.actions.startLoadingHistory());
    try {
      const response = await axios.post(
        `/attendance/${attendanceId}/anamnesis`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateAttendanceDiagnosis(attendanceId, data) {
  return async () => {
    dispatch(PacientSlice.actions.startLoadingHistory());
    try {
      const response = await axios.post(
        `/attendance/${attendanceId}/diagnosis`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateAttendanceAttachments(attendanceId, data) {
  return async () => {
    dispatch(PacientSlice.actions.startLoadingHistory());
    try {
      const response = await axios.post(
        `/attendance/${attendanceId}/attachments`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}
