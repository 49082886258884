import { useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Link,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuPages } from 'src/data/MenuPages';
import { MenuSettings } from 'src/data/MenuSettings';
import { permissionEnum } from 'src/guards/PermissionEnum';
import { getUserPermissions } from 'src/guards/PermissionGuard';
import useContainsAdm from 'src/hooks/useContainsAdm';
import Scrollbar from './Scrollbar';
import useResponsive from '../hooks/useResponsive';
import { IconButtonAnimate } from './animate';
import Iconify from './Iconify';
import { useDispatch, useSelector } from '../redux/store';
import { setActive, setOpen } from '../redux/slices/navBar';
import { getUser } from '../redux/slices/user';
import MenuPopover from './MenuPopover';
import { getClinicWithUser } from '../pages/clinic_settings/slices/clinicSlice';
import createAvatar from '../utils/createAvatar';
import { logout } from '../pages/login/slices/loginSlice';
import { REUFYPAY_URL_REGISTER } from '../config';

let pages = [];
let settings = [];

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSettings, setIsSettings] = useState(false);
  const [menuItems, setMenuItems] = useState(settings);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdmin = useContainsAdm();
  const isDesktop = useResponsive('up', 'lg');
  const { user } = useSelector(state => state.user);
  const { clinic } = useSelector(state => state.clinic);

  const handleReufyPayRegisterPage = () => {
    window.open(REUFYPAY_URL_REGISTER, '_blank');
  };
  useEffect(() => {
    const userPermissions = getUserPermissions();

    const pagesToAdd = MenuPages;

    const settingsToAdd = MenuSettings;

    const hasPermission = item => {
      if (
        !item.requiredPermission ||
        userPermissions.includes(permissionEnum.Admin)
      ) {
        return true;
      }
      return userPermissions.includes(item.requiredPermission);
    };

    const filterPages = pages => {
      return pages.filter(page => {
        const hasPagePermission =
          hasPermission(page) ||
          (page.children && page.children.some(hasPermission));
        if (hasPagePermission) {
          if (page.children) {
            page.children = filterPages(page.children);
          }
          return true;
        }
        return false;
      });
    };

    const filterSettings = settings => {
      return settings.filter(setting => {
        if (
          setting.key === 'meu-perfil' &&
          userPermissions.includes(permissionEnum.Admin)
        ) {
          return false;
        }
        return hasPermission(setting);
      });
    };

    const filteredPages = filterPages(pagesToAdd);
    const filteredSettings = filterSettings(settingsToAdd);

    pages = filteredPages;
    settings = filteredSettings;
  }, [user]);

  const haveName = async () => {
    const res = await getClinicWithUser();
  };

  useEffect(() => {
    getUser();
    haveName();
  }, []);

  const handleOpenMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleOpenSettings = e => {
    setMenuItems(settings);
    setIsSettings(true);
    handleOpenMenu(e);
  };

  const handleOpenSubmenu = (e, submenu) => {
    setMenuItems(submenu);
    setIsSettings(false);
    handleOpenMenu(e);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleGoTo = to => {
    if (to) {
      navigate(to);
    }
    setAnchorEl(null);
  };

  const onClickLogOut = () => {
    window.localStorage.clear();
    logout();
    navigate('/login');
    window.location.reload();
  };

  const isOpen = Boolean(anchorEl);

  return (
    <AppBar position="fixed">
      <Box pr={3} pl={isDesktop ? 3 : 1}>
        <Toolbar disableGutters>
          {!isDesktop &&
            (pathname.includes('admin/clinica') ||
              pathname.includes('admin/profissional') ||
              pathname.includes('admin/financas') ||
              pathname.includes('admin/tiss') ||
              pathname.includes('admin/pacientes/')) && (
              <IconButtonAnimate
                onClick={() => dispatch(setOpen(true))}
                sx={{ mr: 1, color: 'text.primary' }}>
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
            )}
          <Link href="/">
            <Box
              component="img"
              src="/assets/logo/logo-white.png"
              sx={{ height: 30, width: 60, display: 'flex' }}
            />
          </Link>
          <Scrollbar>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                ml: !isDesktop ? 2 : 5,
                height: '64px',
                pr: 5,
              }}
              gap={5}>
              {pages?.map(page => {
                if (page.children) {
                  return (
                    <Typography
                      key={page.key}
                      variant="subtitle2"
                      onClick={e => handleOpenSubmenu(e, page.children)}
                      sx={{
                        py: 2,
                        mb: 0.5,
                        color: 'white',
                        userSelect: 'none',
                        alignSelf: 'center',
                        cursor: 'pointer',
                      }}>
                      {page.label}
                    </Typography>
                  );
                }

                return (
                  <Link
                    href={`/${page.key}`}
                    key={page.key}
                  >
                    <Typography
                      key={page.key}
                      variant="subtitle2"
                      sx={{
                        my: 2,
                        color: 'white',
                        margin: 0,
                        display: 'flex',
                        height: '100%',
                        alignItems: 'center',
                        borderBottom: theme =>
                          pathname === `/${page.key}`
                            ? `4px solid ${theme.palette.primary.dark}`
                            : `4px solid transparent`,
                      }}>
                      {page.label}
                    </Typography>
                  </Link>
                );
              })}
              { isAdmin && (
                <Link
                  href="/admin/clinica"
                  key="ReufyPay"
                  onClick={async () => {
                    handleReufyPayRegisterPage();
                  }}>
                  <Typography
                    key="ReufyPay"
                    variant="subtitle2"
                    sx={{
                      my: 2,
                      color: 'white',
                      margin: 0,
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                    }}>
                    Reufy Pay
                  </Typography>
                </Link>
              )}
            </Box>
          </Scrollbar>
          <Box sx={{ flexGrow: 0, display: 'flex', ml: 2 }}>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                alignItems: 'flex-end',
                color: 'white',
                mr: 1,
              }}>
              <Typography variant="subtitle2">{user?.name}</Typography>
              <Typography variant="caption">{clinic?.name}</Typography>
            </Box>
            <Tooltip title="Abrir Menu">
              <IconButton
                id="profile-button"
                sx={{ p: 0 }}
                aria-controls={isOpen ? 'dropdown-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                onClick={handleOpenSettings}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                  }}>
                  <Avatar
                    alt={user?.name}
                    src={user?.image}
                    sx={{
                      mr: 2,
                      color: theme => theme.palette.common.white,
                      backgroundColor: theme => theme.palette.primary.light,
                    }}>
                    {createAvatar(user?.name).name}
                  </Avatar>
                  <ExpandMore />
                </Box>
              </IconButton>
            </Tooltip>
            <MenuPopover
              id="dropdown-menu"
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleCloseMenu}
              arrow={isSettings ? 'top-right' : 'top-left'}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: isSettings ? 'right' : 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: isSettings ? 'right' : 'left',
              }}
              sx={{
                width: isSettings ? 300 : 'auto',
                mt: 1,
              }}>
              {menuItems.map(page => (
                <MenuItem
                  sx={{ pl: 1 }}
                  key={page.key}
                  onClick={() => handleGoTo(page.to)}>
                  <Iconify width={24} height={24} icon={page.icon} />
                  <Box ml={1} />
                  {page.label}
                </MenuItem>
              ))}
              {isSettings && (
                <MenuItem sx={{ pl: 1 }} onClick={onClickLogOut}>
                  <Iconify width={24} height={24} icon="ri-logout-box-r-line" />
                  <Box ml={1} />
                  Sair
                </MenuItem>
              )}
            </MenuPopover>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Header;
